<script lang="ts">
import { ComponentPublicInstance, defineComponent, ref } from 'vue'

import LogoSvg from '@/assets/svg/logo.svg'
export type PreloaderPublicInstance = ComponentPublicInstance & {
  visible: boolean
}

export default defineComponent({
  name: 'ThePreloader',
  components: { LogoSvg },
  setup (props, { expose }) {
    const publicInstance = {
      visible: ref(true)
    }
    expose(publicInstance)
    return publicInstance
  }
})
</script>

<template>
  <transition>
    <div v-if="visible" id="app-loading">
      <LogoSvg class="logo" />
    </div>
  </transition>
</template>

<style lang="scss" scoped>
#app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  width: rem(80px);
  height: rem(8px);

  @screen md {
    width: rem(160px);
    height: rem(18px);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
